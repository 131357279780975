import { Navigate, Route } from "react-router-dom";
import AuthLayout from "../components/layout/AuthLayout/";
import Login from "../components/pages/AuthPages/Login";
import HomePage from "../components/pages/HomePage";
import DashboardLayout from "../components/layout/DashboardLayout";
import UserManagement from "../components/pages/User-management";
import Business from "../components/pages/Business";
import AddBusiness from "../components/pages/Business/AddBusiness";
import Reports from "../components/pages/Reports";
import PlansAndPayments from "../components/pages/PlansAndPayments";

import Verification from "../components/pages/AuthPages/Verification";
import ForgotPassword from "../components/pages/AuthPages/ForgotPassword";
import ResetPassword from "../components/pages/AuthPages/ResetPassword";
import BusinessView from "../components/pages/Business/View";
import AddPlans from "../components/pages/PlansAndPayments/AddPlans";
import ProtectedRoute from "./ProtectedRoute";
import GuestGuard from "./GuestGaurd";

const routes = [
  {
    path: "/",
    element: <Navigate to="/auth" replace />,
  },
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        index: true, // Make this the index route
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "verify",
        element: <Verification />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword type="reset" />,
      },
      {
        path: "create-password",
        element: <ResetPassword type="create" />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Route path="/" element={<HomePage />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "user-management",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<UserManagement />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "business/*",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<Business />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "add-business",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<AddBusiness />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "add-plans&payements",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<AddPlans />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "add-plans&payements/edit/:id",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<AddPlans />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "business/edit/:id",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<AddBusiness />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "business/view/:id",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<BusinessView />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "plans",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<PlansAndPayments />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "reports",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<Reports />} />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export default routes;

// export const dashboardRoutes = (
//   <Suspense>
//     <Routes>
//       <Route path="/dashboard" element={<HomePage />} />
//     </Routes>
//   </Suspense>
// );
