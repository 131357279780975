import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SelectDropdown from "../../../atoms/Dropdown";
import { statusData, userRoles } from "../../../../utils/staticData/ModalData";
import { patchData, postData } from "../../../../services";
import { useCookies } from "react-cookie";
import Error from "../../../atoms/Error";

const UserForm = ({
  initialValues,
  id,
  getUserManagementList,
  handleCloseModal,
}) => {
  console.log("initialValues", initialValues);

  const [cookies] = useCookies(["t"]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    role: Yup.string().required("Role is required"),
  });

  const handleSubmit = async (values) => {
    const tempPayload = {
      email: values?.email,
      name: values?.name,
      role: values?.role,
    };
    try {
      const res = await postData({
        endpoint: "UserManagement/addUser",
        data: tempPayload,
        params: {},
        token: cookies.t,
      });
      if (res) {
        getUserManagementList();
        handleCloseModal();
      }
    } catch (exp) {
      console.log("exp", exp);
    }
  };

  const handleEdit = async (values) => {
    try {
      const res = await patchData({
        endpoint: "UserManagement/editUser",
        data: values,
        params: {
          user_id: initialValues?.id,
        },
        token: cookies.t,
      });
      if (res) {
        getUserManagementList();
        handleCloseModal();
      }
    } catch (exp) {
      console.log("exp", exp);
    }
  };

  return (
    <Formik
      initialValues={{
        name: initialValues?.name || "",
        email: initialValues?.email || "",
        role: initialValues?.role || "",
        is_active: initialValues?.is_active || "",
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
    >
      {({ dirty, isValid, values, setFieldValue, touched,errors }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <Field type="text" id="name" name="name" placeholder="Enter Name"
            className={`${
              touched.name && errors.name ? "error-input" : ""
            }`}
          />
          {touched.name && errors.name && (
          <Error error={errors.name} />
          )}
          </div>

          <div className="form-group mt-3">
            <label htmlFor="email">Email</label>
            <Field
              type="email"
              id="email"
              name="email"
              placeholder="Enter Email"
              className={`${
                touched.email && errors.email ? "error-input" : ""
              }`}
            />
            {touched.email && errors.email && (
            <Error error={errors.email} />
            )}
          </div>

          <div className="form-group mt-3">
            <label htmlFor="role">Role</label>
            <SelectDropdown
              data={userRoles}
              placeholder="Select Role"
              onChange={(option) => {
                setFieldValue("role", option);
              }}
              selectedValue={initialValues?.role}
            />
          </div>

          {initialValues && (
            <div className="form-group mt-3">
              <label htmlFor="role_id">Status</label>
              <SelectDropdown
                data={statusData}
                placeholder="Select Status"
                onChange={(option) => {
                  setFieldValue("is_active", option);
                }}
                selectedValue={initialValues?.is_active}
                className={`${
                  touched.videoName && errors.videoName ? "error-input" : ""
                }`}
              />
              {touched.videoName && errors.videoName && (
              <Error error={errors.videoName} />
              )}
            </div>
          )}

          <div className="d-flex justify-content-end">
            {initialValues ? (
              <button
                type="button"
                className="btn btn-primary"
                disabled={!isValid}
                onClick={() => handleEdit(values)}
              >
                Update
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isValid || !dirty}
              >
                Send Invite
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default UserForm;
