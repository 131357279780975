import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import HomeIcon from "../../icons/homeIcon";
import BusinessIcon from "../../icons/businessIcon";
import "./navbar.scss";
import UserIcon from "../../icons/UserIcon";
import Payments from "../../icons/Payments";
import LogoutModal from "../Modals/Logout";
import CircleAvatar from "../CirclerAvatar";
import Logo from "../../../assets/images/light-logo.svg";
import { useSelector } from "react-redux";
import { RoleData } from "../../../utils/staticData/roleData";

const Navbar = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // Add the active class
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  // Clean up function to remove the active class
  const removeActive = () => {
    setIsActive(false);
  };

  const isLinkActive = (path) => {
    // Check if the current path starts with the provided path or if the current path is '/dashboard' (including exact match)
    if (path === "/dashboard/home" && location.pathname === "/dashboard") {
      return "active-nav";
    } else {
      return location.pathname.startsWith(path) ? "active-nav" : "";
    }
  };

  return (
    <>
      <header className="App-header">
        <nav className="navbar">
          <div className="icon" onClick={() => navigate("/dashboard")}>
            <img src={Logo} height="45px" alt="Logo" />
          </div>
          <ul className={`navMenu ${isActive ? "active" : ""}`}>
            <li onClick={removeActive}>
              <NavLink
                to=""
                className={`navLink ${isLinkActive("/dashboard/home")}`}
                // ${isLinkActive("/dashboard/home")}
              >
                <HomeIcon />
                Dashboard
              </NavLink>
            </li>
            <li onClick={removeActive}>
              <NavLink
                to="business"
                className={`navLink ${isLinkActive("/dashboard/business")}`}
                // ${isLinkActive("/dashboard/companies")
              >
                <BusinessIcon />
                Businesses
              </NavLink>
            </li>
            {userInfo?.role === RoleData.ADMIN && (
              <li onClick={removeActive}>
                <NavLink
                  to="user-management"
                  className={`navLink ${isLinkActive(
                    "/dashboard/user-management"
                  )}`}
                  // ${isLinkActive("/dashboard/companies")
                >
                  <UserIcon />
                  User Management
                </NavLink>
              </li>
            )}
            <li onClick={removeActive}>
              <NavLink
                to="plans"
                className={`navLink ${isLinkActive("/dashboard/plans")} `}
                // ${isLinkActive("/dashboard/companies")
              >
                <Payments />
                Plans and Payments
              </NavLink>
            </li>
            {userInfo?.role === RoleData.ADMIN && (
              <li onClick={removeActive}>
                <NavLink
                  to="reports"
                  className={`navLink ${isLinkActive("/dashboard/reports")}`}
                  // ${isLinkActive("/dashboard/companies")
                >
                  <UserIcon />
                  Reports
                </NavLink>
              </li>
            )}
          </ul>
          <div className="left-side ">
            <div onClick={handleProfileMenu} className="cursor-pointer">
              <CircleAvatar />
            </div>

            <div
              className={`hamburger ${isActive ? "active" : ""}`}
              onClick={toggleActiveClass}
            >
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              style: {
                width: "200px",
                borderRadius: "16px",
                marginTop: "20px", // Add margin-top to create space
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
                fontWeight: "500px !important",
                // marginRight : '2rem'
              },
            }}
          >
            <MenuItem
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setAnchorEl(null);
              }}
            >
              <span style={{ color: "#BE0F00" }}>Logout</span>
            </MenuItem>
          </Menu>
        </nav>
      </header>
      <LogoutModal
        isActive={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};
export default Navbar;
