import React from "react";

const Payments = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="18"
      height="18"
    >
      <path d="M12,16c-2.206,0-4-1.794-4-4s1.794-4,4-4,4,1.794,4,4-1.794,4-4,4Zm0-6c-1.103,0-2,.897-2,2s.897,2,2,2,2-.897,2-2-.897-2-2-2Zm-7-3c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm13,3c0,.552,.448,1,1,1s1-.448,1-1-.448-1-1-1-1,.448-1,1Zm-13,3c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm13,3c0,.552,.448,1,1,1s1-.448,1-1-.448-1-1-1-1,.448-1,1Zm-1.001,5c-1.634,0-3.098-.399-4.513-.785-1.348-.368-2.62-.715-3.986-.715-1.571,0-2.562,.101-3.419,.349-1.206,.347-2.474,.113-3.48-.644-1.017-.765-1.6-1.933-1.6-3.205v-7.548c0-2.063,1.299-3.944,3.233-4.681,1.341-.512,2.609-.771,3.768-.771,1.634,0,3.097,.399,4.513,.785,1.348,.368,2.62,.715,3.986,.715,1.57,0,2.562-.101,3.419-.349,1.208-.347,2.476-.113,3.481,.644,1.017,.765,1.6,1.933,1.6,3.205v7.548h0c0,2.063-1.3,3.944-3.234,4.681-1.341,.512-2.608,.771-3.768,.771Zm-8.499-3.5c1.634,0,3.097,.399,4.513,.785,1.348,.368,2.62,.715,3.986,.715,.914,0,1.942-.215,3.056-.64,1.183-.45,1.946-1.554,1.946-2.812v-7.548c0-.637-.293-1.223-.803-1.606-.499-.375-1.126-.493-1.725-.321-1.051,.303-2.202,.427-3.974,.427-1.634,0-3.097-.399-4.513-.785-1.348-.368-2.62-.715-3.986-.715-.915,0-1.942,.215-3.056,.64-1.183,.45-1.946,1.554-1.946,2.812v7.548c0,.637,.293,1.223,.803,1.606,.499,.375,1.126,.493,1.724,.32,1.051-.303,2.203-.427,3.974-.427Z" />
    </svg>
  );
};

export default Payments;
