import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

export const instance = axios.create({
  baseURL: `${baseURL}`, // Your base URL
  headers: {
    "Content-Type": "application/json",
    "User-Role": "Customer",
  },
});

export const postData = async ({ endpoint, data, params, token }) => {
  try {
    const response = await instance.post(endpoint, data, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    // Set isLoading to false when the API call is complete
    const result = { data: response.data, isLoading: false };
    return result;
  } catch (error) {
    console.error("Error posting data:", error);
    // Set isLoading to false in case of an error
    const errorResult = { error, isLoading: false };
    throw errorResult;
  }
};

export const postFormData=async([endpoint,params,token,data])=>{
  try{
    const response=await instance.post(endpoint,data,{params,
      headers:{
        Authorization:`Bearer ${token}`,
        "Content-Type":"multipart/form-data"
      },
      });
      const result={data:response.data,isLoading:false};
      return result;
  } catch(error){
    console.error("Error posting Form: ",error);
    const errorResult={error,isLoading:false};
    throw errorResult;
  }
};

export const getData = async ({ endpoint, params, token }) => {
  try {
    const response = await instance.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting data:", error);
    throw error;
  }
};

export const patchData = async ({ endpoint, data, params, token }) => {
  try {
    const response = await instance.patch(endpoint, data, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error patching data:", error);
    throw error;
  }
};

export const deleteData = async ({ endpoint, params, token }) => {
  try {
    const response = await instance.delete(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const result = { data: response.data, isLoading: false };
    return result;
  } catch (error) {
    console.error("Error deleting data:", error);
    // Set isLoading to false in case of an error
    const errorResult = { error, isLoading: false };
    throw errorResult;
  }
};
