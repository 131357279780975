import { Field, Formik } from "formik";
import PageCard from "../../../atoms/PageCard";
import PageHeader from "../../../atoms/PageHeader";
import * as yup from "yup";
import InputLayout from "../../../atoms/InputLayout";
import SelectDropdown from "../../../atoms/Dropdown";
import { countryCodeData } from "../../../../utils/staticData/CountryCodeData";
import { getData, patchData, postData } from "../../../../services";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  phoneNumberAfter,
  phoneNumberIntial,
} from "../../../../helpers/helpers";
import Error from "../../../atoms/Error";
import SmallLoader from "../../../atoms/loader";

const AddBusiness = () => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isloading, setIsLoading] = useState(false);
  const [getBusinessDetails, setGetBusinessDetails] = useState([]);

  const initialValues = {
    businessName: getBusinessDetails?.name || "",
    typeOfBusiness: getBusinessDetails?.type || "",
    email: getBusinessDetails?.user_email || "",
    Domain: getBusinessDetails?.domain || "",
    contactName: getBusinessDetails?.user_name || "",
    countryCode: phoneNumberIntial(getBusinessDetails?.phone) || "",
    contactNumber: phoneNumberAfter(getBusinessDetails?.phone) || "",
    alternatecountryCode:
      phoneNumberIntial(getBusinessDetails?.alternate_phone) || "",
    alternatecontact:
      phoneNumberAfter(getBusinessDetails?.alternate_phone) || "",
    address: getBusinessDetails?.address || "",
    city: getBusinessDetails?.city || "",
    state: getBusinessDetails?.state || "",
    country: getBusinessDetails?.country || "",
    gstNumber: getBusinessDetails?.gst_number || "",
    pincode: getBusinessDetails?.pincode || "",
  };

  const validationSchema = yup.object().shape({
    businessName: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    Domain: yup
      .string()
      .matches(
        /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(?:\/[^\s]*)?$/,
        "Invalid URL"
      )
      .required("Domain is Required"),
    contactName: yup.string().required("Contact Name is required"),
    countryCode: yup.string().required("CountryCode is required"),
    contactNumber: yup.number().required("ContactNumber is required"),
    address: yup.string().required("Address is required"),
    typeOfBusiness: yup.mixed().required("Type is required"),
    alternatecontact: yup.number().required("Alternative number is required"),
    gstNumber: yup.string().required("GstNumber is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    country: yup.string().required("Country is required"),
    pincode: yup.string().required("Pincode is required"),
  });

  const getBusinessDetailsByID = async () => {
    try {
      const res = await getData({
        endpoint: "BusinessManagement/getBusinessDetails",
        params: {
          business_id: id,
        },
        token: cookies.t,
      });
      setGetBusinessDetails(res);
    } catch (exp) {
      console.log("exp", exp);
    }
  };
  useEffect(() => {
    getBusinessDetailsByID();
  }, [id]);

  const handleSubmitData = async (values) => {
    setIsLoading(true);
    const payload = {
      name: values?.businessName,
      type: values?.typeOfBusiness,
      domain: values?.Domain,
      user_name: values?.contactName,
      address: values?.address,
      country: values?.country,
      city: values?.city,
      state: values?.state,
      gst_number: values?.gstNumber,
      phone: values?.countryCode + values?.contactNumber,
      alternate_phone: values?.alternatecountryCode + values?.alternatecontact,
      user_email: values?.email,
      pincode: values?.pincode,
    };
    try {
      const res = await postData({
        endpoint: "BusinessManagement/addBusiness",
        data: payload,
        params: {},
        token: cookies.t,
      });
      if (res) {
        setIsLoading(false);
        navigate("/dashboard/business");
      }
    } catch (exp) {
      console.log("Exp", exp);
    }
  };
  const handleEditSubmitData = async (values) => {
    setIsLoading(true);
    const updatePayload = {
      name: values?.businessName,
      type: values?.typeOfBusiness,
      domain: values?.Domain,
      user_name: values?.contactName,
      address: values?.address,
      country: values?.country,
      city: values?.city,
      state: values?.state,
      gst_number: values?.gstNumber,
      phone: values?.countryCode + values?.contactNumber,
      alternate_phone: values?.alternatecountryCode + values?.alternatecontact,
      user_email: values?.email,
      pincode: values?.pincode,
    };
    try {
      const res = await patchData({
        endpoint: "BusinessManagement/editBusiness",
        params: {
          business_id: id,
        },
        data: updatePayload,
        token: cookies.t,
      });
      if (res) {
        setIsLoading(false);
        navigate("/dashboard/business");
      }
      console.log("res", res);
    } catch (exp) {
      console.log("exp", exp);
    }
  };

  return (
    <>
      <Formik
        key={Math.random}
        initialValues={initialValues}
        validationSchema={validationSchema}
        // onSubmit={handleSubmit}
        validateOnChange
        validateOnBlur
        enableReinitialize
        // onChange={onChange}
      >
        {({
          isValid,
          dirty,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleChange,
        }) => (
          <>
            <PageHeader title={"Add Business"} />
            <div className="add-page-display mb-4">
              <div className="form-card" style={{ width: "100%" }}>
                <PageCard classes="p-4 pt-3">
                  <form action="">
                    <div className="row g-3 mt-3">
                      <InputLayout>
                        <label htmlFor="">Business Name</label>
                        <Field
                          type="text"
                          name="businessName"
                          id="businessName"
                          placeholder="Business Name"
                          className={`${
                            touched.businessName && errors.businessName
                              ? "error-input"
                              : ""
                          }`}
                        />
                        {touched.businessName && errors.businessName && (
                          <Error error={errors.businessName} />
                        )}
                      </InputLayout>
                      <InputLayout>
                        <label htmlFor="">Type of Business </label>
                        <Field
                          type="text"
                          name="typeOfBusiness"
                          placeholder="Type Of Business"
                          id="typeOfBusiness"
                          className={`${
                            touched.typeOfBusiness && errors.typeOfBusiness
                              ? "error-input"
                              : ""
                          }`}
                        />
                        {touched.typeOfBusiness && errors.typeOfBusiness && (
                          <Error error={errors.typeOfBusiness} />
                        )}
                      </InputLayout>
                      <InputLayout>
                        <label htmlFor="">Contact Name</label>
                        <Field
                          type="text"
                          name="contactName"
                          placeholder="Contact Name"
                          id="contactName"
                          className={`${
                            touched.contactName && errors.contactName
                              ? "error-input"
                              : ""
                          }`}
                        />
                        {touched.contactName && errors.contactName && (
                          <Error error={errors.contactName} />
                        )}
                      </InputLayout>
                    </div>

                    <div className="row g-3 mt-2">
                      <InputLayout>
                        <label htmlFor="">Contact Email</label>
                        <Field
                          type="text"
                          name="email"
                          placeholder="Contact Email"
                          id="email"
                          className={`${
                            touched.email && errors.email ? "error-input" : ""
                          }`}
                        />
                        {touched.email && errors.email && (
                          <Error error={errors.email} />
                        )}
                      </InputLayout>
                      <InputLayout>
                        <label htmlFor="">Domain</label>
                        <Field
                          type="text"
                          name="Domain"
                          placeholder="Domain"
                          id="Domain"
                          className={`${
                            touched.Domain && errors.Domain ? "error-input" : ""
                          }`}
                        />
                        {touched.Domain && errors.Domain && (
                          <Error error={errors.Domain} />
                        )}
                      </InputLayout>
                      <InputLayout>
                        <label htmlFor="">GST Number</label>
                        <Field
                          type="text"
                          name="gstNumber"
                          placeholder="GST NUmber"
                          id="gstNumber"
                          className={`${
                            touched.gstNumber && errors.gstNumber
                              ? "error-input"
                              : ""
                          }`}
                        />
                        {touched.gstNumber && errors.gstNumber && (
                          <Error error={errors.gstNumber} />
                        )}
                      </InputLayout>
                    </div>
                    <div className="row g-3 mt-3">
                      <InputLayout>
                        <div className="d-flex justify-content-sm-between">
                          <div className="w-100">
                            <label htmlFor="">Company Phone Number</label>
                            <div className="row g-3 w-100">
                              <div className="col-4">
                                <SelectDropdown
                                  data={countryCodeData}
                                  placeholder="Code"
                                  onChange={(option) => {
                                    setFieldValue("countryCode", option);
                                  }}
                                  isClearable={false}
                                  selectedValue={phoneNumberIntial(
                                    getBusinessDetails?.phone
                                  )}
                                />
                              </div>
                              <div
                                className="col-8 pe-0"
                                style={{ flex: "auto" }}
                              >
                                <Field
                                  style={{ width: "100%" }}
                                  type="number"
                                  name="contactNumber"
                                  placeholder="Enter Contact Number"
                                  id="contactNumber"
                                  onChange={(e) => {
                                    const numericInput = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setFieldValue(
                                      "contactNumber",
                                      numericInput
                                    );
                                  }}
                                  className={`${
                                    touched.contactNumber &&
                                    errors.contactNumber
                                      ? "error-input"
                                      : ""
                                  }`}
                                />
                                {touched.contactNumber &&
                                  errors.contactNumber && (
                                    <Error error={errors.contactNumber} />
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </InputLayout>
                      <InputLayout>
                        <div>
                          <label htmlFor="">Alternate Phone Number</label>
                          <div className="row g-3 w-100">
                            <div className="col-4">
                              <SelectDropdown
                                data={countryCodeData}
                                placeholder="Code"
                                onChange={(option) => {
                                  setFieldValue("alternatecountryCode", option);
                                }}
                                isClearable={false}
                                selectedValue={phoneNumberIntial(
                                  getBusinessDetails?.alternate_phone
                                )}
                              />
                            </div>
                            <div
                              className="col-8 pe-0"
                              style={{ flex: "auto" }}
                            >
                              <Field
                                style={{ width: "100%" }}
                                type="number"
                                name="alternatecontact"
                                placeholder="Alternate Contact Number"
                                id="contactNumber"
                                onChange={(e) => {
                                  const numericInput = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  setFieldValue(
                                    "alternatecontact",
                                    numericInput
                                  );
                                }}
                                className={`${
                                  touched.alternatecontact &&
                                  errors.alternatecontact
                                    ? "error-input"
                                    : ""
                                }`}
                              />
                              {touched.alternatecontact &&
                                errors.alternatecontact && (
                                  <Error error={errors.alternatecontact} />
                                )}
                            </div>
                          </div>
                        </div>
                      </InputLayout>
                      <InputLayout>
                        <label htmlFor="">Address</label>
                        <Field
                          type="text"
                          name="address"
                          placeholder="Address"
                          id="address"
                          className={`${
                            touched.address && errors.address
                              ? "error-input"
                              : ""
                          }`}
                        />
                        {touched.address && errors.address && (
                          <Error error={errors.address} />
                        )}
                      </InputLayout>
                    </div>
                    <div className="row g-3 mt-2">
                      <InputLayout>
                        <label htmlFor="">City</label>
                        <Field
                          type="text"
                          name="city"
                          placeholder="City"
                          id="city"
                          className={`${
                            touched.city && errors.city ? "error-input" : ""
                          }`}
                        />
                        {touched.city && errors.city && (
                          <Error error={errors.city} />
                        )}
                      </InputLayout>
                      <InputLayout>
                        <label htmlFor="">Pincode</label>
                        <Field
                          type="text"
                          name="pincode"
                          placeholder="Pincode"
                          id="pincode"
                          className={`${
                            touched.pincode && errors.pincode
                              ? "error-input"
                              : ""
                          }`}
                        />
                        {touched.pincode && errors.pincode && (
                          <Error error={errors.pincode} />
                        )}
                      </InputLayout>
                      <InputLayout>
                        <label htmlFor="">State</label>
                        <Field
                          type="text"
                          name="state"
                          placeholder="State"
                          id="state"
                          className={`${
                            touched.state && errors.state ? "error-input" : ""
                          }`}
                        />
                        {touched.state && errors.state && (
                          <Error error={errors.state} />
                        )}
                      </InputLayout>
                    </div>
                    <div className="row g-3 mt-2" style={{ width: "33.5%" }}>
                      <InputLayout>
                        <label htmlFor="">Country</label>
                        <Field
                          type="text"
                          name="country"
                          placeholder="Country"
                          id="country"
                          className={`${
                            touched.country && errors.country
                              ? "error-input"
                              : ""
                          }`}
                        />
                        {touched.country && errors.country && (
                          <Error error={errors.country} />
                        )}
                      </InputLayout>
                    </div>
                  </form>
                </PageCard>
              </div>
            </div>
            {/* {console.log("values", values)} */}
            <footer className="footer">
              {id ? (
                <button
                  type="submit"
                  className={`btn btn-primary px-4 mt-0 `}
                  onClick={() => handleEditSubmitData(values)}
                  disabled={!isValid || !dirty}
                >
                  Update Details
                </button>
              ) : (
                <button
                  type="submit"
                  className={`btn btn-primary px-4 mt-0 `}
                  onClick={() => handleSubmitData(values)}
                  disabled={!isValid || !dirty}
                >
                  Save Details
                </button>
              )}
            </footer>
          </>
        )}
      </Formik>
      {isloading && <SmallLoader />}
    </>
  );
};
export default AddBusiness;
