import { Field, Formik } from "formik";
import PageHeader from "../../../atoms/PageHeader";
import * as yup from "yup";
import PageCard from "../../../atoms/PageCard";
import InputLayout from "../../../atoms/InputLayout";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { getData, patchData, postData } from "../../../../services";
import { useEffect, useState } from "react";
import Error from "../../../atoms/Error";

const AddPlans=()=>{
    const [cookies] = useCookies(["t"]);
    const navigate = useNavigate();
    const { id } = useParams();
    const [planData,setPlanData]=useState([])
    console.log("planData :",planData)
    const initialValues={
        videoName: planData?.name || "",
        Price:planData?.price || "",
        startTime: planData?.start_time || "",
        endTime: planData?.end_time || "",
        isActive:planData?.is_active || false ,
    }
    const validattionSchema=yup.object().shape({
        videoName: yup.string().required("Name is required"),
        Price: yup.string().required("Price is required"),
        startTime:yup.string().required("startTime is required"),
        endTime:yup.string().required("endTime is required"),

    })
    const getPlanByID = async () => {
        try {
          const res = await getData({
            endpoint: "Plan/getPlanById",
            params: {
              id: id,
            },
            token: cookies.t,
          });
          console.log("res :",res)
          setPlanData(res);
        } catch (exp) {
          console.log("exp", exp);
        }
      };
      useEffect(() => {
        getPlanByID();
      }, [id]);
    const handleSubmitData = async (values) => {
        const payload = {
          name: values?.videoName,
          price:values?.Price,
          start_time: values?.startTime,
          end_time: values?.endTime,
          is_active: values?.isActive
        };
        try {
          const res = await postData({
            endpoint: "Plan/addPlan",
            data: payload,
            params: {},
            token: cookies.t,
          });
          if (res) {
            navigate("/dashboard/plans");
          }
        } catch (exp) {
          console.log("Exp", exp);
        }
      };
      const handleEditSubmitData = async (values) => {
        const updatePayload = {
            name: values?.videoName,
            price:values?.Price,
            start_time: values?.startTime,
            end_time: values?.endTime,
            is_Active: values?.isActive
        };
        try {
          const res = await patchData({
            endpoint: "Plan/editPlan",
            params: {
              plan_id: id,
            },
            data: updatePayload,
            token: cookies.t,
          });
          if (res) {
            navigate("/dashboard/plans");
          }
          console.log("res", res);
        } catch (exp) {
          console.log("exp", exp);
        }
      };
    return(
        <Formik
         key={Math.random}
         initialValues={initialValues}
         validationSchema={validattionSchema}
         enableReinitialize
         validateOnBlur
         validateOnChange
        >
            {({
                isValid,
                dirty,
                setFieldValue,
                values,
                touched,
                errors
            })=>(
                <>
                <PageHeader title={"Add Plans And Payments"}/>
                <div className="add-page-display mb-4">
                    <div className="form-card">
                        <PageCard classes="p-4 pt-3">
                            <form action="">
                                <div className="row g-3 mt-3">
                                    <InputLayout>
                                    <label htmlFor="">Video Name</label>
                                    <Field 
                                    type="text"
                                    name="videoName"
                                    id="videoName"
                                    placeholder="Video Name"
                                    className={`${
                                        touched.videoName && errors.videoName ? "error-input" : ""
                                      }`}
                                    />
                                    {touched.videoName && errors.videoName && (
                                    <Error error={errors.videoName} />
                                    )}
                                    </InputLayout>
                                    <InputLayout>
                                    <label htmlFor="">Price</label>
                                    <Field 
                                    type="number"
                                    name="Price"
                                    id="price"
                                    placeholder="Price"
                                    className={`${
                                        touched.Price && errors.Price ? "error-input" : ""
                                      }`}
                                    />
                                     {touched.Price && errors.Price && (
                                    <Error error={errors.Price} />
                                    )}
                                    </InputLayout>
                                </div>
                                <div className="row g-3 mt-3">
                                    <InputLayout>
                                    <label htmlFor="">Start Time</label>
                                    <Field 
                                    type="number"
                                    name="startTime"
                                    id="startTime"
                                    placeholder="Start Time"
                                    className={`${
                                        touched.startTime && errors.startTime ? "error-input" : ""
                                      }`}
                                    />
                                     {touched.startTime && errors.startTime && (
                                    <Error error={errors.startTime} />
                                    )}
                                    </InputLayout>
                                    <InputLayout>
                                    <label htmlFor="">End Time</label>
                                    <Field 
                                    type="number"
                                    name="endTime"
                                    id="endTime"
                                    placeholder="End Time"
                                    className={`${
                                        touched.endTime && errors.endTime ? "error-input" : ""
                                      }`}
                                    />
                                     {touched.endTime && errors.endTime && (
                                    <Error error={errors.endTime} />
                                    )}
                                    </InputLayout>
                                </div>
                                <div className="row g-3 mt-3">
                                <InputLayout formStyle={{width:"50%",flexDirection:"row",alignItems:"center",gap:"30px"}}>
                              <label htmlFor="">Active</label>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ width: "25%", height: "50px" }}
                              >
                                <div className="d-flex align-items-center">
                                  <label className="radioContainer d-flex align-items-center gap-1">
                                    <input
                                      type="radio"
                                      name="isActive"
                                      value={values.isActive === true}
                                      onChange={() => {
                                        setFieldValue("isActive", true);
                                      }}
                                      checked={values?.isActive === true}
                                    />
                                    <span className="radio"></span>
                                    <span className="label">Yes</span>
                                  </label>
                                </div>

                                <div className="d-flex align-items-center">
                                  <label className="radioContainer d-flex align-items-center gap-1">
                                    <input
                                      type="radio"
                                      name="isActive"
                                      value={values.isActive === false}
                                      onChange={() => {
                                        // debugger;
                                        setFieldValue("isActive", false);
                                      }}
                                      checked={values?.isActive === false}
                                    />
                                    <span className="radio"></span>
                                    <span className="label">No</span>
                                  </label>
                                </div>
                              </div>
                            </InputLayout>
                                </div>

                            </form>
                        </PageCard>
                    </div>
                </div>
                <footer className="footer">
              {id ? (
                <button
                  type="submit"
                  className={`btn btn-primary px-4 mt-0 `}
                  onClick={() => handleEditSubmitData(values)}
                  // disabled={!isValid || !dirty}
                >
                  Update Details
                </button>
              ) : (
                <button
                  type="submit"
                  className={ `btn btn-primary px-4 mt-0`}
                  onClick={() => handleSubmitData(values)}
                  disabled={!isValid || !dirty}
                >
                  Save Details
                </button>
              )}
            </footer>
                </>
            )}
        </Formik>
    )
}
export default AddPlans;