import { Field, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Error from "../../../../atoms/Error";
import toast from "react-hot-toast";
import { postData } from "../../../../../services";
import { useCookies } from "react-cookie";
import {
  errorStyles,
  confirmationStyles,
} from "../../../../../assets/styles/toast";
import { expirationTime } from "../../../../../helpers/expirationTime";

const Form = () => {
  const navigate = useNavigate();

  const [cookie, setCookie] = useCookies(["auid"]);
  const [passwordVisible, setPasswordVisible] = useState(false); // State to manage password visibility
  const [passwordValue, setPasswordValue] = useState(false);
  const passwordRef = useRef(null);

  // Form
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const lowercaseEmail = values.email.toLowerCase();
      const passwordcheck = values.password.replace(/^[\s]+|[\s]+$/g, "");
      const updatedValues = { password: passwordcheck, email: lowercaseEmail };

      // dispatch(setLoading(true));
      const postDataResult = await postData({
        endpoint: "AdminAuth/login",
        data: {},
        params: updatedValues,
      });
      const resultData = postDataResult.data;

      if (resultData.status && resultData.response === 200) {
        // dispatch(setLoading(false));
        setCookie("auid", resultData.data.user_id, {
          path: "/",
          expires: expirationTime(),
        });
        localStorage.setItem("countdownStartTime", "30");
        navigate("/auth/verify");
        toast.success(resultData.message, {
          duration: 1000,
          style: confirmationStyles,
        });
      } else {
        toast.error(resultData.message, {
          duration: 1000,
          style: errorStyles,
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("Error Occured", {
        duration: 1000,
        style: errorStyles,
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (passwordValue === "") {
      passwordRef.current.focus();
    }
  }, [passwordValue]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        setSubmitting,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email</label>
            <Field
              type="text"
              name="email"
              placeholder="Enter Email Address"
              className={`${
                touched.email && errors.email ? "error-input" : ""
              }`}
              autoComplete="off"
              onInput={(e) => {
                // Remove spaces while typing
                e.target.value = e.target.value.replace(/\s/g, "");
              }}
            />
            {touched.email && errors.email && <Error error={errors.email} />}
          </div>

          <div className="form-group mt-4">
            <label>Password</label>
            {values.password && values.password.length > 0 ? (
              <>
                <div className="input-group">
                  <Field
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    placeholder="Enter your Password"
                    className={`${
                      touched.password && errors.password ? "error-input" : ""
                    }`}
                    autoFocus
                    autoComplete="off"
                    innerRef={passwordRef}
                    onInput={(e) => setPasswordValue(e.target.value)}
                  />
                  {values.password && values.password.length > 0 && (
                    <div
                      className={`input-group-append ${
                        touched.password && errors.password ? "error-input" : ""
                      }`}
                    >
                      <span
                        className="info "
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        {passwordVisible ? "Hide" : "Show"}
                      </span>
                    </div>
                  )}
                </div>
                {touched.password && errors.password && (
                  <Error error={errors.password} />
                )}
              </>
            ) : (
              <>
                <Field
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  placeholder="Enter your Password"
                  className={`${
                    touched.password && errors.password ? "error-input" : ""
                  }`}
                  autoComplete="off"
                  innerRef={passwordRef}
                  onInput={(e) => setPasswordValue(e.target.value)}
                />
                {touched.password && errors.password && (
                  <Error error={errors.password} />
                )}
              </>
            )}
          </div>

          <button
            className="btn btn-primary w-100 mt-4 mb-4"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Loading" : "Login"}
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          <button
            className="btn btn-outline-dark w-100"
            onClick={() => navigate("/auth/forgot-password")}
            type="button"
          >
            Forgot Password?
          </button>
        </form>
      )}
    </Formik>
  );
};

export default Form;
