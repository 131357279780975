import TypeDiv from '../../../atoms/TypeDiv/index.tsx';
import './styles.scss'
const LabelValueDisplay = ({ label, children, style }) => {
    return (
      <div className="view-info-container" style={style}>
        <div className="label">{label}</div>
        <div className="data">
          <div className="colon">:</div>
          {children}
        </div>
      </div>
    );
  };
const View=({data})=>{
  console.log("d---> ",data)
    return(
        <div className="request-info-container">
        <LabelValueDisplay label="Video Name">{data?.name}</LabelValueDisplay>
        <LabelValueDisplay label="Price">
          {data?.price}
        </LabelValueDisplay>
        <LabelValueDisplay label="Start Time">{data?.start_time}</LabelValueDisplay>
  
        <LabelValueDisplay label="End Time">
          {data?.end_time}
        </LabelValueDisplay>
         <LabelValueDisplay label="Active"><TypeDiv data={data?.is_active ? "Yes" : "No"} color={data?.is_active ? "rgb(181, 228, 202)" : "rgb(255, 183, 183"}/></LabelValueDisplay>
         </div>
    )
}
export default View;