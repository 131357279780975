import React, { useEffect, useState } from "react";
import Select from "react-select";
import { customDropdownStyles, filterDropdownStyles } from "./dropdownIndex";

const SelectDropdown = ({
  id,
  multiSelect = false,
  selectedValue,
  onChange,
  classes,
  placeholder,
  data,
  format,
  isDisabled,
  type,
}) => {
  const options = data || [];

  const [selectedOptions, setSelectedOptions] = useState(null);

  useEffect(() => {
    if (multiSelect && selectedValue) {
      const initialSelectedOptions = options.filter((option) =>
        selectedValue?.includes(option.value)
      );
      setSelectedOptions(initialSelectedOptions);
    } else if (!multiSelect && selectedValue != null) {
      const initialSelectedOption =
        options.find((option) => option.value === selectedValue) || null;
      setSelectedOptions(initialSelectedOption);
    } else {
      const defaultSelectedOptions = multiSelect ? [] : null;
      setSelectedOptions(defaultSelectedOptions);
    }
  }, [selectedValue, multiSelect, options]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);

    if (multiSelect) {
      let selectedValues;
      if (format === "all") {
        selectedValues = selectedOptions.map((option) => option);
      } else {
        selectedValues = selectedOptions.map((option) => option.value);
      }
      onChange(selectedValues);
    } else {
      if (format === "all") {
        onChange(selectedOptions);
      } else {
        onChange(selectedOptions ? selectedOptions.value : null);
      }
    }
  };

  return (
    <Select
      className={`basic-single ${classes}`}
      classNamePrefix="select"
      options={options}
      value={selectedOptions}
      isMulti={multiSelect}
      onChange={handleSelectChange}
      styles={type === "filter" ? filterDropdownStyles : customDropdownStyles}
      key={id}
      placeholder={placeholder}
      isDisabled={isDisabled}
    />
  );
};

export default SelectDropdown;
