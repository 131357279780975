import { useCookies } from "react-cookie";
import CustomButton from "../CustomButton";
import CloseIcon from "../../icons/closeIcon";
import { useNavigate } from "react-router-dom";

const LogoutModal = ({ width = "400px", onClose, isActive }) => {
  const navigate = useNavigate();
  const [, , removeCookies] = useCookies(["t", "un", "email", "uid", "role"]);
  const cookieNames = ["t", "aun", "email", "uid", "role"];
  console.log("cookieNames", cookieNames);

  const handleLogout = () => {
    cookieNames.forEach((cookieName) => {
      removeCookies(cookieName, { path: "/" });
    });
    navigate("/auth");
  };

  const styles = {
    width: width,
  };
  return (
    <div className={`modal ${isActive ? "active" : ""}`}>
      <div className="modal-content" style={styles}>
        <div className="modal-body py-4">
          <CloseIcon />
          <p className="modal-heading mb-0 mt-2">Logout</p>
          <p className="modal-description">
            Are you sure do you want to Logout.
          </p>
          <div className="d-flex w-100 mt-3">
            <CustomButton
              text="No"
              handleClick={onClose}
              style={{ flex: "1 0 45%" }}
            />
            {/* Adjust the style for the "Confirm" button */}
            <button
              className="btn btn-primary mt-0"
              onClick={handleLogout}
              style={{ flex: "1 0 45%", marginLeft: "10px" }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
