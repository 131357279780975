import React, { useCallback, useEffect, useState } from "react";
import CustomDataGrid from "../../atoms/CustomDataGrid";
import PageCard from "../../atoms/PageCard";
import PageHeader from "../../atoms/PageHeader";
import Search from "../../atoms/Search";
import Pagination from "../../atoms/Pagination";
import style from "./index.module.scss";
import CustomButton from "../../atoms/CustomButton";
import Add from "../../icons/add";
import { useNavigate } from "react-router-dom";
import { deleteData, getData } from "../../../services";
import { useCookies } from "react-cookie";
import EditIcon from "../../icons/editIcon";
import EyeIcon from "../../icons/eyeIcon";
import DeleteModal from "../../atoms/Modals/DeleteModal";
import DeleteIcon from "../../icons/deleteIcon";
import NoContentCard from "../../atoms/NoContentCard";
import { useSelector } from "react-redux";
import { RoleData } from "../../../utils/staticData/roleData";
const Business = () => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [tableData, setTableData] = useState();
  const [searchedValue, setSearchedValue] = useState("");
  const [businessDataList, setBusinessDataList] = useState([]);

  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  console.log("userInfo", userInfo);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const [deleteForm, setDeleteForm] = useState({
    isActive: false,
    modalId: "",
    name: "",
    formData: {},
  });
  const getBusinessData = async () => {
    try {
      const res = await getData({
        endpoint: "BusinessManagement/getAllBusiness",
        token: cookies.t,
        params: {
          page: currentPage,
          page_limit: itemsPerPage,
          search_query: searchedValue,
        },
      });

      setTableData(res?.data);
      setBusinessDataList(res);
    } catch (exp) {
      console.log("exp", exp);
    }
  };

  const handleDeleteBusiness = async (data) => {
    try {
      const res = await deleteData({
        endpoint: "BusinessManagement/deleteBusiness",
        params: { business_id: data?.id },
        token: cookies?.t,
      });
      if (res) {
        getBusinessData();
        setDeleteForm((prev) => ({
          ...prev,
          isActive: false,
          formData: {},
        }));
      }
    } catch (exp) {
      console.log("exp", exp);
    }
  };

  const handleDelete = (data) => {
    setDeleteForm((prev) => ({
      ...prev,
      isActive: true,
      formData: data,
    }));
  };
  const handleOnClose = () => {
    setDeleteForm((prev) => ({
      ...prev,
      isActive: false,
      formData: {},
    }));
  };

  useEffect(() => {
    getBusinessData();
  }, [currentPage, itemsPerPage, searchedValue]);

  const CustomNoRowsOverlay = React.memo(() => {
    return <NoContentCard title="No Items Added Yet!" type="Search" />;
  });

  const handleEdit = (data) => {
    navigate(`/dashboard/business/edit/${data?.id}`);
  };

  const handleView = (data) => {
    navigate(`/dashboard/business/view/${data?.id}`);
  };
  const handleSearchEmit = (searchValue) => {
    setSearchedValue(searchValue);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        <div>{params?.row?.name}</div>;
      },
    },
    {
      field: "user_email",
      headerName: "Email",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        <div>{params?.row?.email}</div>;
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        <div>{params?.row?.phone}</div>;
      },
    },
    {
      field: "domain",
      headerName: "Domain",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        <div>{params?.row?.domain}</div>;
      },
    },

    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {userInfo?.role !== RoleData.VIEWER && (
              <span
                className={style.editIcon}
                type="button"
                onClick={() => handleEdit(params.row)}
              >
                <EditIcon />
              </span>
            )}
            <span type="button" onClick={() => handleView(params.row)}>
              <EyeIcon />
            </span>
            {userInfo?.role !== RoleData.VIEWER && (
              <span
                type="button"
                onClick={() => handleDelete(params.row)}
                className="ms-3"
              >
                <DeleteIcon />
              </span>
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      <PageHeader title="Businesses" />
      <PageCard>
        <div className={style.searchAddWrap}>
          <Search onSearchEmit={handleSearchEmit} />
          {userInfo?.role !== RoleData.VIEWER && (
            <CustomButton
              text="Add New"
              icon={<Add fill="white" />}
              classes={"d-flex align-items-center"}
              type="btn-primary"
              handleClick={() => {
                navigate("/dashboard/add-business");
              }}
            />
          )}
        </div>
        <CustomDataGrid
          getRowId={(row) => row.id}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
          rows={tableData || []}
          columns={columns}
        />
        {businessDataList?.total_record >= 10 && (
          <Pagination
            totalItems={businessDataList?.total_record}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
        <DeleteModal
          isActive={deleteForm.isActive}
          onClose={handleOnClose}
          title={deleteForm.formData.name}
          onClick={() => {
            handleDeleteBusiness(deleteForm.formData);
          }}
        />
      </PageCard>
    </>
  );
};
export default Business;
