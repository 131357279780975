import React, { useCallback, useEffect, useState } from "react";
import CustomDataGrid from "../../atoms/CustomDataGrid";
import PageHeader from "../../atoms/PageHeader";
import PageCard from "../../atoms/PageCard";
import Search from "../../atoms/Search";
import Pagination from "../../atoms/Pagination";
import Add from "../../icons/add";
import CustomButton from "../../atoms/CustomButton";
import style from "./index.module.scss";
import Modal from "../../atoms/Modals/Modal";
import { generateRandomNumber } from "../../../utils/Helpers/randomFunction";
import UserForm from "./Form";
import { deleteData, getData } from "../../../services";
import { useCookies } from "react-cookie";
import EditIcon from "../../icons/editIcon";
import DeleteIcon from "../../icons/deleteIcon";
import DeleteModal from "../../atoms/Modals/DeleteModal";
import NoContentCard from "../../atoms/NoContentCard";

const UserManagement = () => {
  const [cookies] = useCookies(["t"]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchedValue, setSearchedValue] = useState("");
  const modalId = generateRandomNumber();
  const [formDataModal, setFormDataModal] = useState({
    isActive: false,
    modalId: "",
    name: "",
    formData: {},
  });
  const [deleteForm, setDeleteForm] = useState({
    isActive: false,
    modalId: "",
    name: "",
    formData: {},
  });

  const [userManagementList, setUserManagementList] = useState([]);
  const [tableData, setTableData] = useState();

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const handleOpenModal = () => {
    setFormDataModal((prev) => ({
      ...prev,
      modalId: modalId,
      isActive: true,
      formData: null,
    }));
  };

  const handleCloseModal = () => {
    setFormDataModal((prev) => ({
      ...prev,
      modalId: modalId,
      isActive: false,
    }));
  };

  const handleEdit = (data) => {
    setFormDataModal((prev) => ({
      ...prev,
      modalId: data?.id,
      isActive: true,
      formData: data,
    }));
  };

  const handleDelete = (data) => {
    setDeleteForm((prev) => ({
      ...prev,
      isActive: true,
      formData: data,
    }));
  };

  const handleOnClose = () => {
    setDeleteForm((prev) => ({
      ...prev,
      isActive: false,
      formData: {},
    }));
  };

  const getUserManagementList = async () => {
    try {
      const res = await getData({
        endpoint: "UserManagement/getAllUsers",
        token: cookies?.t,
        params: {
          page: currentPage,
          page_limit: itemsPerPage,
          search_query: searchedValue,
        },
      });
      setUserManagementList(res);
      setTableData(res?.data);
    } catch (exp) {
      console.log("exp", exp);
    }
  };
  useEffect(() => {
    getUserManagementList();
  }, [itemsPerPage, currentPage, searchedValue]);

  const handleDeleteUser = async (data) => {
    try {
      const res = await deleteData({
        endpoint: "UserManagement/deleteUser",
        params: { user_id: data?.id },
        token: cookies?.t,
      });
      if (res) {
        getUserManagementList();
        setDeleteForm((prev) => ({
          ...prev,
          isActive: false,
          formData: {},
        }));
      }
    } catch (exp) {
      console.log("exp", exp);
    }
  };
  const handleSearchEmit = (searchValue) => {
    setSearchedValue(searchValue);
  };

  const CustomNoRowsOverlay = React.memo(() => {
    return <NoContentCard title="No Items Added Yet!" type="Search" />;
  });
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        return <div>{params?.row?.name}</div>;
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        <div>{params?.row?.email}</div>;
      },
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        <div>{params?.row?.role}</div>;
      },
    },
    {
      field: "is_active",
      headerName: "Status",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.is_active ? <div>Active</div> : <div>InActive</div>}
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span type="button" onClick={() => handleEdit(params.row)}>
              <EditIcon />
            </span>

            <span
              type="button"
              onClick={() => handleDelete(params.row)}
              className="ms-3"
            >
              <DeleteIcon />
            </span>
          </>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader title="User Management" />
      <PageCard>
        <div className={style.searchAddWrap}>
          <Search onSearchEmit={handleSearchEmit} />
          <CustomButton
            text="Add New"
            icon={<Add fill="white" />}
            classes={"d-flex align-items-center"}
            type="btn-primary"
            handleClick={() => {
              handleOpenModal();
            }}
          />
        </div>

        <CustomDataGrid
          getRowId={(row) => row.id}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
          rows={tableData || []}
          columns={columns}
        />

        {userManagementList?.total_record >= 10 && (
          <Pagination
            totalItems={userManagementList?.total_record}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
      </PageCard>
      <Modal
        id={formDataModal.modalId}
        title="User Management"
        onClose={handleCloseModal}
        width="632px"
        isActive={formDataModal.isActive}
      >
        <UserForm
          initialValues={formDataModal.formData}
          id={formDataModal.modalId}
          getUserManagementList={getUserManagementList}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
      <DeleteModal
        isActive={deleteForm.isActive}
        onClose={handleOnClose}
        title={deleteForm.formData.name}
        onClick={() => {
          handleDeleteUser(deleteForm.formData);
        }}
      />
    </>
  );
};
export default UserManagement;
